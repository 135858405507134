"use client";

import {
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	VisuallyHidden,
} from "@chakra-ui/react";
import { ReactNode, useEffect, useState } from "react";
import { URLModal as ReactURLModal, closeModal } from "react-url-modal";
import { useSiteSettings } from "src/lib/hooks";
import { removeKeyFromObjectByList } from "src/lib/utils";
import { useHasLoginToken } from "src/queries/emil/account";
import { modalRoutes, portalRouteNames } from "./modalRoutes";
import { Modal } from "../Modal";
import { NoSSR } from "../NoSSR/NoSSR";

const ModalWrapper: React.FC<{ children: ReactNode }> = ({ children }) => {
	return (
		<NoSSR>
			<Modal isOpen={true} onClose={closeModal}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader />
					<VisuallyHidden>
						<ModalCloseButton />
					</VisuallyHidden>
					<ModalBody>{children}</ModalBody>
					<ModalCloseButton data-testid="close-modal" />
				</ModalContent>
			</Modal>
		</NoSSR>
	);
};

export const CustomURLModal: React.FC = () => {
	const {
		brandConfig: { hasPortal },
	} = useSiteSettings();
	const hasLoginToken = useHasLoginToken();

	const [filteredModalRoutes, setFilteredModalRoutes] = useState<
		Record<string, any>
	>({});

	useEffect(() => {
		let filterOut: Array<string> = [];

		if (!hasLoginToken) {
			filterOut = [...filterOut, ...portalRouteNames];
		}

		if (!hasPortal) {
			filterOut = [...filterOut, "Login"];
		}

		const filteredRoutes = removeKeyFromObjectByList(
			modalRoutes,
			filterOut,
		);
		setFilteredModalRoutes(filteredRoutes);
	}, [hasPortal, hasLoginToken]);

	return (
		<ReactURLModal Wrapper={ModalWrapper} modals={filteredModalRoutes} />
	);
};

// 🔬 TBD: Please evaluate
